export const routes = {
  home: "/",
  festival: "/festival",
  contact: "/contact",
  donation: "/donation",
  visitUs: "/visit-us",
  media: "/media",
  bookDistribution: "/services/book-distribtion",
  deityWorship: "/services/deity-worship",
  devoteeKitchen: "/services/devotee-kitchen",
  gaushala: "/services/gaushala",
  guestHouse: "/services/guest-house",
  iskconYouthForum: "/srevices/iskcon-youth-forum",
  kirtanAtIskcon: "/services/kirtan-at-iskcon",
  lifeTimeMembership: "/services/life-time-membership",
  govindaFood: "/services/govinda-food",
  cancellationPolicy: "/footer/cancellation-policy",
  privacyPolicy: "/footer/privacy-policy",
  termsAndCondition: "/footer/terms-and-condition,",
  annaDaan: "/donation-pages/anna-daan",
  ehindu: "/donation-pages/e-hindu",
  generaldonation: "/donation-pages/general-donation",
  gauSeva: "/donation-pages/gau-seva",
  vidyadaan: "/donation-pages/vidya-daan",
  deityseva: "/donation-pages/deity-seva",
  givesrimadbhagavatam: "/donation-pages/give-srimad-bhagavatam",
  recurriumdonation: "/donation-pages/recurrium-donation",
  donationMainPage: "/donation-pages/donation-main-page",
  sadhubhojan: "/donation-pages/sadhu-bhojan",
  radharaniKitchenSeva: "/donation-pages/radharani-kitchen-seva",
  monthlySeva: "/donation-pages/monthly-radharani-kithcen-seva",
  gitaDaan: "/donation-pages/gita-daan",
  paymentMsg: "/donation-pages/payment/success",
};
