import { routes } from "../../routes";

export const dropdownServicesInfo = [
  { name: "Life Membership", url: routes.lifeTimeMembership },
  { name: "Kirtan At Iskcon", url: routes.kirtanAtIskcon },
  { name: "Iskcon Youth Forum", url: routes.iskconYouthForum },
  { name: "Guest House", url: routes.guestHouse },
  { name: "Goushala", url: routes.gaushala },
  { name: "Devotee Kitchen", url: routes.devoteeKitchen },
  { name: "Deity Worship", url: routes.deityWorship },
  { name: "Book Distribution", url: routes.bookDistribution },
  { name: "Govinda's Food", url: routes.govindaFood },
];

export const dropdownDonationInfo = [
  { name: "Food for Life (Annna-Daan)", url: routes.annaDaan },
  { name: "E-Hundi", url: routes.ehindu },
  { name: "General-Donation", url: routes.generaldonation },
  { name: "Gau Seva", url: routes.gauSeva },
  { name: "Monthly Radharani's Kitchen Seva", url: routes.monthlySeva },
  { name: "Vidya-Daan", url: routes.vidyadaan },
  { name: "Give-Srimad-donation ", url: routes.givesrimadbhagavatam },
  { name: "Recurrium-Donation", url: routes.recurriumdonation },
  // { name: "Janmashtmi-Seva", url: routes.janmashtmiSeva },
  { name: "Sadhu-Bhojan", url: routes.sadhubhojan },
  { name: "Radharani-Kitchen-Seva", url: routes.radharaniKitchenSeva },
  { name: "Gita-Daan", url: routes.gitaDaan },
  { name: "Deity-Seva", url: routes.deityseva },
];
