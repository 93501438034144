import { routes } from "../../routes";

export const socialMediaIcon = [
  {
    name: "Facebook",
    href: "https://www.facebook.com/iskconvvn/",
    icon: "facebook-f",
  },
  {
    name: "Twitter",
    href: "https://twitter.com/iskcon_vvn",
    icon: "twitter",
  },

  {
    name: "Telegram",
    href: "https://t.me/iskconvvn",
    icon: "telegram",
  },
  {
    name: "Instagram",
    href: "https://instagram.com/iskcon_vallabhvidyanagar?igshid=YmMyMTA2M2Y=",
    icon: "instagram",
  },
  {
    name: "youtube",
    href: "https://www.youtube.com/channel/UCkxYEp2omi0yV3LcutvQJ-w",
    icon: "youtube",
  },
];

export const festivalInfo = [{ title: "festival", path: routes.festival }];

export const dropdownServicesInfo = [
  { title: "Life Membership", path: routes.lifeTimeMembership },
  { title: "Kirtan At Iskcon", path: routes.kirtanAtIskcon },
  { title: "ISKCON Youth Forum", path: routes.iskconYouthForum },
  { title: "Guest House", path: routes.guestHouse },
  { title: "Goushala", path: routes.gaushala },
  { title: "Devotee Kitchen", path: routes.devoteeKitchen },
  { title: "Deity Worship", path: routes.deityWorship },
  { title: "Book Distribution", path: routes.bookDistribution },
  { title: "Govinda's Food", path: routes.govindaFood },
];

export const dropdownDonationPagesInfo = [
  { title: "Food for Life (Annna-Daan)", path: routes.annaDaan },
  { title: "E-Hundi", path: routes.ehindu },
  { title: "General-Donation", path: routes.generaldonation },
  { title: "Gau Seva", path: routes.gauSeva },
  { title: "Monthly Radharani's Kitchen Seva", path: routes.monthlySeva },
  { title: "Vidya Daan", path: routes.vidyadaan },
  { title: "Give-Shrimad-Bhagavatam", path: routes.givesrimadbhagavatam },
  { title: "Recurrium Donation", path: routes.recurriumdonation },
  { title: "Sadhu-Bhojan", path: routes.sadhubhojan },
  { title: "Radharani-Kitchen-Seva", path: routes.radharaniKitchenSeva },
  { title: "Gita-Daan", path: routes.gitaDaan },
  { title: "Deity-Seva", path: routes.deityseva },
];
