import { DonationCategory } from "../Payment/constant";

export const givesrimadbhagavatam = [
  "Bhadra Purnima - Give Srimad Bhagavatam",
  "If on the full moon day of the month of Bhādra one places Śrīmad-Bhāgavatam on a golden throne and gives it as a gift, he will attain the supreme transcendental destination. -Srimad-Bhagavatam 12.13.13",
  "Just as the Ganga is the greatest of all rivers, Lord Acyuta the supreme among deities and Lord Shambhu is the greatest of Vaisnavas, so Srimad-Bhagavatam is the greatest of all Puranas. -Srimad-Bhagavatam 12.13.16",
  "Gift Srimad Bhagavtam to your friends, family members, colleagues, neighbours or anyone you can think off.",
];

export const donationCategories: DonationCategory[] = [
  {
    title: " Give Srimad Bhagavatam",
    donations: [
      { id: 1, amount: 6900, title: "Hindi" },
      { id: 2, amount: 8900, title: "English" },
      { id: 3, amount: 6800, title: "Bengali" },
      { id: 4, amount: 7500, title: "Gujarati" },
      { id: 5, amount: 7500, title: "Kannada" },
      { id: 6, amount: 5200, title: "Marathi" },
      { id: 7, amount: 7300, title: "Odia" },
      { id: 8, amount: 5597, title: "Tamil" },
      { id: 9, amount: 6300, title: "Telugu" },
    ],
  },
];
